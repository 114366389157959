import "../styles/Contact.css";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Dna from "./Dna";

const schema = yup.object().shape({
  fullName: yup.string().required("É necessário colocar seu nome!"),
  phone: yup
    .string()
    .required("É necessário colocar seu número de telefone!")
    .min(8, "O telefone precisa ter no mínimo 8 caracteres")
    .max(15, "O telefone precisa ter no máximo 15 caracteres"),
  email: yup
    .string()
    .email("E-mail inválido")
    .required("É necessário colocar seu e-mail!"),
  subject: yup.string().required("É necessário colocar um assunto!"),
  text: yup.string().required("É necessário escrever sua mensagem!")
});

const Contact = () => {
  interface Inputs {
    fullName: string;
    phone: number;
    email: string;
    subject: string;
    text: string;
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({
    resolver: yupResolver(schema)
  });

  // const onSubmit = () => {
  //   action = "https://formsubmit.co/lucas.melo.form@gmail.com";
  //   method = "POST";
  // };
  return (
    <div className="contact">
      <div className="contact-container">
        <h2 className="contact-title">Fale conosco</h2>
        <h4 className="contact-subtitle">
          Agende já a sua terapia ou tire alguma duvida!
        </h4>
        <form
          action="https://formsubmit.co/wilian.p.melo@gmail.com"
          method="POST"
          /*onSubmit={handleSubmit(onSubmit)}*/
        >
          <div className="contact-row1">
            <div className="contact-element1">
              <input
                type="text"
                placeholder="Nome Completo"
                {...register("fullName")}
              />
              <p className="contact-error">{errors.fullName?.message}</p>
            </div>
            <div className="contact-element2">
              <input
                type="text"
                placeholder="Telefone"
                {...register("phone")}
              />
              <p className="contact-error">{errors.phone?.message}</p>
            </div>
          </div>

          <div className="contact-row2">
            <div className="contact-element3">
              <input type="text" placeholder="Email" {...register("email")} />
              <p className="contact-error">{errors.email?.message}</p>
            </div>
            <div className="contact-element4">
              <input
                type="text"
                placeholder="Assunto"
                {...register("subject")}
              />
              <p className="contact-error">{errors.subject?.message}</p>
            </div>
          </div>

          <textarea placeholder="Mensagem..." {...register("text")}></textarea>
          <p className="contact-error">{errors.text?.message}</p>

          <input type="submit" />
        </form>
        <Dna />
      </div>
    </div>
  );
};

export default Contact;
