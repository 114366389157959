import Computer from "../../assets/computer.png";
import Desease from "../../assets/desease.png";
import Pain from "../../assets/pain.png";
import "../styles/FeedHome.css";

const FeedHome = () => {
  return (
    <div className="feed-home">
      <div className="feed-home-container">
        <h1 className="feed-home-title">O que é o Biofeedback?</h1>
        <div className="feed-home-dash"></div>
        <div className="feed-home-steps">
          <div className="feed-home-step1">
            <img src={Computer} width="80px"></img>
            <h4>1.</h4>
            <p>
              O Biofeedback utiliza uma interface de computador para enviar ao
              cliente frequências que restabelecem o equilíbrio das células de
              órgãos e tecidos do corpo.
            </p>
          </div>
          <div className="feed-home-step2">
            <img src={Desease} width="90px"></img>
            <h4>2.</h4>
            <p>
              Estudos científicos internacionais atestam que o Biofeedback
              auxilia no tratamento de diversas doenças físicas e emocionais.
            </p>
          </div>
          <div className="feed-home-step3">
            <img src={Pain} width="90px"></img>
            <h4>3.</h4>
            <p>
              O Biofeedback é uma técnica não invasiva, indolor e que atua
              diretamente na causa das disfunções físicas e emocionais.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedHome;
