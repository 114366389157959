import ProfilePic from "../assets/profile2.png";
import "../styles/Profile.css";

export const Profile = () => {
  return (
    <div className="profile">
      <h2 className="profile-title">Terapeuta</h2>
      <div className="profile-container">
        <div className="profile-leftside">
          <img src={ProfilePic} width="300px" className="profile-image"></img>
          <h3 className="profile-name">Wilian Pinheiro de Melo</h3>
        </div>
        <div className="profile-rightside">
          <p className="profile-text">
            Formado em análise de sistemas com extensão universitária pela
            FIA/USP, dedicou-se por 15 anos à implementação de sistemas de
            informática gerindo equipes de desenvolvimento em empresa
            automobilística multinacional.
          </p>
          <p className="profile-text">
            Em 2005, pós graduou-se em gestão de empresas pela Fundação Getúlio
            Vargas, passando a empreender no mercado de distribuição de produtos
            para saúde.
          </p>
          <p className="profile-text">
            Conhece, em 2017, a terapia de Quantum Biofeedback enquanto cliente
            e, cativado pelas suas potencialidades, forma-se terapeuta desta
            técnica certificado pela The Quantum Academies, passando a atuar
            desde então. Nasce assim, o Prána Studio.
          </p>
          <p className="profile-text">
            Dando voz ao anseio de ajudar na recuperação da saúde das pessoas,
            em 2020 forma-se Terapeuta Homeopata pelo Instituto Tecnológico
            Hahnemann e, em 2021, torna-se terapeuta na técnica de Biomagnetismo
            pela SBBio - Sociedade Brasileira de Biomagnetismo.
          </p>
        </div>
      </div>
    </div>
  );
};
