import Body from "../../assets/corpo.png";
import "../styles/BioInfo.css";
import Background from "../../assets/biomag1.png";

const BioInfo = () => {
  return (
    <div className="bio-info">
      <div
        className="bio-info-container"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover"
        }}
      >
        <div className="bio-info-leftside">
          <h2 className="bio-info-title">
            O Biomagnetismo é uma abordagem científica e terapêutica que estuda
            e trabalha os fenômenos magnéticos produzidos pelo corpo humano.
          </h2>
          <p className="bio-info-info1">
            O Biomagnetismo atua com eficiência na causa das disfunções do
            corpo, e não apenas nos sintomas. Trata inúmeras doenças, como a
            depressão, ansiedade, diabetes, câncer e muitas outras, sejam
            doenças físicas e emocionais.
          </p>
          <p className="bio-info-info2">
            É uma alternativa indolor, indicada para adultos, crianças e animais
            reestabelecerem o equilíbrio e bem-estar do corpo. O Biomagnetismo
            pode ser realizado simultaneamente com procedimentos médicos e
            outras terapias.
          </p>
        </div>
        <div className="bio-info-rightside">
          {/* <img
            className="bio-info-image"
            src={Body}
            style={{ opacity: "0.5" }}
          ></img> */}
        </div>
      </div>
    </div>
  );
};

export default BioInfo;
