import "../styles/Dna.css";

const Dna = () => {
  return (
    <div className="material">
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
      <div className="dna"></div>
    </div>
  );
};

export default Dna;
