import "../styles/About.css";
import Vitruviano from "../assets/vitruviano.jpg";

export const About = () => {
  return (
    <div className="about">
      <div className="about-container">
        <div className="about-leftside">
          <h2 className="about-title">Sobre o Prána</h2>
          <p className="about-text">
            Contribuir para a recuperação e a manutenção da saúde física, mental
            e emocional das pessoas, é o nosso objetivo! Utilizamos para isso as
            técnicas de Biomagnetismo e Biofeedback: práticas complementares,
            não invasivas e comprovadas cientificamente, que atuam na causa dos
            problemas e não simplesmente nos sintomas. Restabeleça sua saúde!!
          </p>
        </div>
        <div className="about-rightside">
          <img
            className="about-image"
            src={Vitruviano}
            width="400px"
            height="400px"
          ></img>
        </div>
      </div>
    </div>
  );
};
