import "../styles/FeedSession.css";
import Body from "../../assets/corpo.png";

const FeedSession = () => {
  return (
    <div className="feed-session">
      <div className="feed-session-container">
        <h2 className="feed-session-title">
          Como decorre a sessão de Biofeedback:
        </h2>
        <p>
          - Inicia-se com uma fase de calibração que avalia parâmetros do corpo
          elétrico do cliente. Oxigenação, hidratação, stress mental, emocional,
          vitalidade celular, Ph elétrico, entre outros são medidos.
        </p>
        <p>
          - Logo a seguir é realizado um teste em que se verifica e classifica a
          reatividade da pessoa a cerca de uma matriz com 12000 substâncias cujo
          resultado será evidenciado na tela do computador para posterior
          decisão dos itens prioritários a se trabalhar na sessão. Durante o
          teste, avalia-se a presença de parasitas, produtos tóxicos, ou
          desequilíbrios em nutrientes (vitaminas, minerais, etc), enzimas,
          neurotransmissores, neuropeptídios, hormônios, desequilíbrios na
          coluna, dentes, processos inflamatórios, infeções, hiper-reatividades
          a determinadas substâncias que poderão ser intolerâncias, alergias,
          questões emocionais, funções cerebrais, sistema cardíaco, nervoso,
          linfático, digestivo, etc.
        </p>
        <p>
          - Com base no resultado do teste e das queixas do cliente,
          selecionam-se programas que enviam frequências (assinaturas
          energéticas) que auxiliarão o organismo a restabelecer os padrões de
          normalidade e saúde física, mental e emocional.
        </p>
        <p>
          - É possível mensurar a reação individual da pessoa a qualquer
          frequência trabalhada ou a qualquer programa de treino utilizado o que
          permite trabalhar de forma eficaz e eficiente.
        </p>
        <p>
          - A duração de cada sessão depende tanto da pessoa quanto do objetivo
          e poderá ter uma duração entre 45m a 1h e 15m.
        </p>
      </div>
      {/* <img
        className="feed-session-image"
        src={Body}
        style={{ opacity: "0.5" }}
      ></img> */}
    </div>
  );
};

export default FeedSession;
