import "../styles/FeedHelps.css";

const FeedHelps = () => {
  return (
    <div className="feed-helps">
      <div className="feed-helps-container">
        <h2 className="feed-helps-title">
          O Biofeedback pode auxiliar nestas e em muitas outras questões:
        </h2>
        <ul className="feed-helps-list">
          <li>Capacidade para descontrair mais rápida e facilmente</li>
          <li>Fadiga crônica, dor</li>
          <li>Regulação do sono, insônia</li>
          <li>Redução do stress, tensão e nervosismo</li>
          <li>Saúde e bem-estar em geral melhoradas</li>
          <li>Redução de raivas, medos, sombras e apreensão</li>
          <li>Ansiedade, depressão</li>
          <li>
            Mobilidade muscular reforçada, flexibilidade e performance
            desportiva, lesões musculares
          </li>
          <li>Clareza mental, memória e atenção reforçadas</li>
          <li>Défice de atenção / problemas de aprendizagem</li>
          <li>Diminuição de dores crónicas ou agudas, dores de cabeça</li>
          <li>Problemas de coluna / dores nas costas</li>
          <li>Sacro cranial</li>
          <li>Problemas de pele</li>
          <li>Problemas circulatórios </li>
          <li>Regulação do sistema imunológico</li>
          <li>Questões degenerativas</li>
          <li>Distúrbios hormonais</li>
          <li>Problemas respiratórios / asma, falta de ar</li>
          <li>Reequilíbrio energético</li>
          <li>Nutrição (equilíbrio de minerais, vitaminas, etc.)</li>
          <li>Desintoxicação</li>
          <li>Perda de peso</li>
          <li>Deixar de fumar</li>
        </ul>
      </div>
    </div>
  );
};

export default FeedHelps;
