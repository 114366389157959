import "../styles/Footer.css";
import Logo from "../assets/prana-logo.png";
import WhatsApp from "../assets/whatsapp.svg";
import Facebook from "../assets/facebook.svg";
import Instagram from "../assets/instagram.svg";

const Footer = () => {
  const URL_WHATS = "https://api.whatsapp.com/send?phone=11981602419";
  const URL_FACE = "https://www.facebook.com/PranaStudioBiofeedback";
  const URL_INSTA = "https://www.instagram.com/pranastudio_terapias/";

  return (
    <div className="footer">
      <div className="footer-container">
        <img src={Logo} width="100px"></img>
        <div className="footer-info-container">
          <div className="footer-info">
            <p style={{ fontWeight: "500" }}>Unidade Ipiranga -</p>
            <p>&nbsp; Rua Agostinho Gomes, 1635 -</p>
            <p>&nbsp; São Paulo</p>
            {/* <p>&nbsp; Tel.:(11)98160-2419</p> */}
          </div>
          <div className="footer-info">
            <p style={{ fontWeight: "500" }}>Unidade São Bernardo -</p>
            <p>&nbsp; Rua Limeira, 91 -</p>
            <p>&nbsp; São Bernardo do Campo</p>
            {/* <p>&nbsp; Tel.:(11)96212-2419</p> */}
          </div>
        </div>
        <div className="footer-network">
          <a href={URL_WHATS} target="_blank">
            <img src={WhatsApp} width="45px"></img>
          </a>
          <a href={URL_FACE} target="_blank">
            <img src={Facebook} width="50px"></img>
          </a>
          <a href={URL_INSTA} target="_blank">
            <img src={Instagram} width="50px"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
