import Footer from "../../components/Footer";
import "../styles/Biofeedback.css";
import FeedHelps from "./FeedHelps";
import FeedHome from "./FeedHome";
import FeedInfo from "./FeedInfo";
import FeedSession from "./FeedSession";
import FeedVideos from "./FeedVideos";

const Biofeedback = () => {
  return (
    <div className="Biofeedback">
      <FeedHome />
      <FeedInfo />
      <FeedSession />
      <FeedHelps />
      <FeedVideos />
      <Footer />
    </div>
  );
};

export default Biofeedback;
