import "../styles/Home.css";
import Logo from "../assets/prana-logo.png";
import { About } from "./About";
import Contact from "./Contact";
import { Profile } from "./Profile";
import Footer from "./Footer";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div className="home">
      <div className="home-container">
        {/* <h1 className="home-title">Prána Studio</h1> */}
        <img src={Logo} width="350px" className="home-title"></img>
        <h3 className="home-subtitle">Saiba mais sobre nossas terapias:</h3>
        {/* <button className="home-button">Saiba mais!</button> */}
        <Link to="/biomagnetismo">
          <button className="home-button btn-mag">Biomagnetismo</button>
        </Link>
        <Link to="/biofeedback">
          <button className="home-button btn-feed">Biofeedback</button>
        </Link>
      </div>
      <About />
      <Profile />
      <Contact />
      <Footer />
    </div>
  );
};
