import Footer from "../../components/Footer";
import BioHome from "./BioHome";
import BioInfo from "./BioInfo";
import BioSurge from "./BioSurge";
import BioVideos from "./BioVideos";
import BioWork from "./BioWork";

const Biomagnetismo = () => {
  return (
    <div className="biomagnetismo">
      <BioHome />
      <BioInfo />
      <BioSurge />
      <BioWork />
      <BioVideos />
      <Footer />
    </div>
  );
};

export default Biomagnetismo;
