import "../styles/BioWork.css";
import Work1 from "../../assets/work1.jpg";
import WorkContainer1 from "../../assets/workcontainer1.jpg";
import WorkContainer2 from "../../assets/workcontainer2.jpg";
import WorkContainer3 from "../../assets/workcontainer3.jpg";
import WorkContainer4 from "../../assets/workcontainer4.jpg";
import Work3 from "../../assets/work3.jpg";

const BioWork = () => {
  return (
    <div className="bio-work">
      <div className="bio-work-container">
        <h2 className="bio-work-title">Como funciona o biomagnetismo?</h2>
        <div className="bio-work-dash"></div>

        <div className="bio-work-steps">
          <div className="bio-work-step1">
            <div className="bio-work-steptitle1">
              <h3 className="bio-work-number">1.</h3>
              <h3 className="bio-work-subtitle">O diagnóstico</h3>
            </div>
            <div className="bio-work-textcontainer-1">
              <div className="bio-work-info1">
                <p className="bio-work-text1">
                  Para rastreio dos pontos ácidos e alcalinos é utilizada a
                  Bioenergética, metodologia desenvolvida em 1964, pelo Dr.
                  George Goodheart, médico americano que descobriu a
                  kinesiologia.
                </p>
                <p className="bio-work-text1">
                  A técnica permite que o terapeuta, através da resposta
                  muscular, acesse informações registradas no DNA da pessoa
                  tratada, como o desequilíbrio de órgãos e tecidos.
                </p>
              </div>
              <div className="bio-work-image1">
                <img src={Work1} width="160px"></img>
              </div>
            </div>
          </div>

          <div className="bio-work-step2">
            <div className="bio-work-steptitle1">
              <h3 className="bio-work-number">2.</h3>
              <h3 className="bio-work-subtitle">O papel dos ímãs</h3>
            </div>
            <div className="bio-work-info2">
              <div className="bio-work-infocontainer1">
                <img
                  src={WorkContainer1}
                  className="bio-work-imagecontainer1"
                  width="200px"
                ></img>
                <p className="bio-work-textcontainer1">
                  Os tecidos com excesso de íons de Hidrogênio (H+) tornam-se
                  ácidos, permitindo o desenvolvimento de vírus e fungos.
                </p>
              </div>
              <div className="bio-work-infocontainer2">
                <img
                  src={WorkContainer2}
                  className="bio-work-imagecontainer2"
                  width="200px"
                ></img>
                <p className="bio-work-textcontainer2">
                  Já os tecidos com excesso de Hidroxila (OH-) tornam-se
                  alcalinos, permitindo o desenvolvimento de bactérias e
                  parasitas.
                </p>
              </div>
              <div className="bio-work-infocontainer3">
                <img
                  src={WorkContainer3}
                  className="bio-work-imagecontainer3"
                  width="140px"
                ></img>
                <p className="bio-work-textcontainer3">
                  A carga positiva do ímã repele os íons de hidrogênio, já a
                  carga negativa repele as hidroxilas, retomando a neutralidade
                  dos tecidos.
                </p>
              </div>
              <div className="bio-work-infocontainer4">
                <img
                  src={WorkContainer4}
                  className="bio-work-imagecontainer4"
                  width="150px"
                ></img>
                <p className="bio-work-textcontainer4">
                  Com os tecidos neutros, os vírus, fungos, bactérias e
                  parasitas que causam as patologias deixam de ter espaço no
                  organismo para seu desenvolvimento.
                </p>
              </div>
            </div>
          </div>

          <div className="bio-work-step3">
            <div className="bio-work-steptitle1">
              <h3 className="bio-work-number">3.</h3>
              <h3 className="bio-work-subtitle">As sessões</h3>
            </div>
            <div className="bio-work-textcontainer-3">
              <div className="bio-work-info3">
                <p className="bio-work-text3">
                  A quantidade de sessões e o tempo de aplicação dos ímãs pode
                  variar dependendo da pessoa, do tipo de tratamento e das
                  respostas dadas pelo corpo no decorrer das sessões. A partir
                  dessas respostas, o terapeuta avaliará a quantidade de sessões
                  que a pessoa deverá realizar. O número médio é de 3 a 4
                  sessões.
                </p>
                <p className="bio-work-text3">
                  A terapia não é aconselhada para grávidas e pessoas que tenham
                  realizado tratamentos quimioterápicos ou radioterápicos. Além
                  disso, pessoas com marca-passo, stent ou grampo cirúrgico de
                  material ferro magnético, devem passar por uma avaliação do
                  terapeuta.
                </p>
              </div>
              <div className="bio-work-image3">
                <img src={Work3}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioWork;
