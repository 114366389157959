import "../styles/FeedInfo.css";
import Background from "../../assets/imgtentativa.jpg";
import FeedDna from "./FeedDna";

const FeedInfo = () => {
  return (
    <div className="feed-info">
      <div
        className="feed-info-container"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover"
          // backgroundPositionY: "-100px"
        }}
      >
        <div className="feed-info-leftside">
          <h2 className="feed-info-title">
            O Biofeedback é um sistema que avalia e equilibra a pessoa como um
            todo, de forma integrada e não invasiva nos aspectos físico, mental,
            emocional e energético.
          </h2>
          <p className="feed-info1">
            Destina-se a pessoas de todas as idades e condições. Não produz
            efeitos secundários nem tem contraindicações. Apenas um cuidado
            especial em gestantes ou pessoas que utilizem marcapasso.
          </p>
          <p className="feed-info2">
            O sistema QUEST9 Biofeedback ajuda a gerir e a reformular padrões de
            stress, fornece aos clientes informações cruciais acerca do stress,
            motivando-os a procederem a mudanças no seu estilo de vida de forma
            a apoiar a saúde e o bem-estar em geral.
          </p>
          <p className="feed-info2">
            O Biofeedback atua no campo elétrico e energético do organismo
            humano sendo uma preciosa ajuda para a resolução dos mais diversos
            problemas e condições diagnosticados por um especialista.
          </p>
          {/* <FeedDna /> */}
        </div>
        <div className="feed-info-rightside"></div>
      </div>
    </div>
  );
};

export default FeedInfo;
