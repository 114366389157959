import "../styles/FeedVideos.css";
import { Player, BigPlayButton } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import FeedVid from "../../assets/biofeedback-vid.mp4";

const BioVideos = () => {
  return (
    <div className="feedvideos">
      <div className="feedvideos-container">
        <h1 className="feedvideos-title">Ainda com dúvidas?</h1>
        <div className="feedvideos-video">
          <div className="feedvideos-video1">
            <Player
              playsInline
              src={FeedVid}
              fluid={false}
              width={600}
              height={400}
            >
              <BigPlayButton position="center" />
            </Player>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioVideos;
