import "../styles/BioVideos.css";
import { Player, BigPlayButton } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import BioVid1 from "../../assets/biomagnetismo-vid.mov";
import BioVid2 from "../../assets/biomagnetismo-vid2.mov";

const BioVideos = () => {
  return (
    <div className="biovideos">
      <div className="biovideos-container">
        <h1 className="biovideos-title">Ainda com dúvidas?</h1>
        <div className="biovideos-video">
          <div className="biovideos-video1">
            <Player
              playsInline
              src={BioVid1}
              fluid={false}
              width={600}
              height={400}
            >
              <BigPlayButton position="center" />
            </Player>
          </div>
          <div className="biovideos-video2">
            <Player
              playsInline
              src={BioVid2}
              fluid={false}
              width={600}
              height={400}
            >
              <BigPlayButton position="center" />
            </Player>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioVideos;
