import Book from "../../assets/book.jpg";
import "../styles/BioSurge.css";

const BioSurge = () => {
  return (
    <div className="bio-surge">
      <div className="bio-surge-container">
        <h2 className="bio-surge-title">
          <strong>Como surgiu</strong> o biomagnetismo?
        </h2>
        <div className="bio-surge-dash"></div>
        <div className="bio-surge-infos">
          <div className="bio-surge-leftside">
            <img src={Book} className="bio-surge-image"></img>
          </div>
          <div className="bio-surge-rightside">
            <h3 className="bio-surge-title2">
              Antes de Cristo, Aristóteles (384 – 322 a.C.), médico grego Galeno
              (200 a.C.) e outros pesquisadores, utilizavam os imãs naturais
              para tratamentos.
            </h3>
            <p className="bio-surge-info1">
              Após Cristo, Ibin Sina – filósofo e médico persa (980 ˜ 1037),
              Paracelsius, médico suíço (1493 ˜ 1541), Franz Mesmer, médico
              alemão (1734 ˜ 1815) e outros, também utilizaram o magnetismo para
              tratar a saúde.
            </p>
            <p className="bio-surge-info2">
              Na década de 80, o médico da NASA, Dr Richard Broeringmeyer,
              percebeu que os astronautas ficavam doentes após o retorno à
              Terra, devido aos desequilíbrios no organismo, causados pela falta
              de gravidade no espaço. Após muita pesquisa descobriu que devido a
              questões emocionais, alimentação e energéticas, moléculas de água
              do corpo se rompem e formam tecidos ácidos (H+) e tecidos
              alcalinos (OH-), que propiciam a proliferação de vírus, fungos,
              bactérias e parasitas, causando as doenças. O Dr. Richard utilizou
              imãs potentes para neutralizar os tecidos com (H+) e com (OH-),
              acabando assim com as doenças físicas e emocionais.
            </p>
            <p className="bio-surge-info3">
              Em 1988, o Dr. Isaac Goiz Duran, médico mexicano e pesquisador da
              cura da AIDS, após conhecer a técnica do Dr. Richard, passou a
              utilizar os imãs em seus pacientes, obtendo curas rápidas.
              Entusiasmado com os resultados, ele passou a pesquisar e
              desenvolver a técnica para todas as doenças, e é considerado o
              grande desenvolvedor do Biomagnetismo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioSurge;
