import "../styles/Header.css";
import { useState } from "react";
import Logo from "../assets/logo-white.png";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import ScrollToTop from "react-scroll-to-top";
import { Home } from "../components/Home";
import Biomagnetismo from "../biomagnetismo/components/Biomagnetismo";
import Biofeedback from "../biofeedback/components/Biofeedback";

export const Header = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <Router>
      <nav className="header">
        <Scroll to="home" spy smooth>
          <Link to="/">
            <img className="header-title" src={Logo} width="100px"></img>
          </Link>
        </Scroll>
        <button
          className="header-button"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          ☰
        </button>
        <div className={isNavExpanded ? "header-menu expanded" : "header-menu"}>
          <ul>
            <li>
              <Scroll to="home" spy smooth>
                <Link
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                  to="/"
                >
                  Home
                </Link>
              </Scroll>
            </li>
            <li>
              <Scroll to="mag-home" spy smooth offset={-100}>
                <Link
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                  to="/biomagnetismo"
                >
                  Biomagnetismo
                </Link>
              </Scroll>
            </li>
            <li>
              <Scroll to="feed-home-title" spy smooth offset={-100}>
                <Link
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                  to="/biofeedback"
                >
                  Biofeedback
                </Link>
              </Scroll>
            </li>
            <li>
              <Scroll to="about" spy smooth offset={-90}>
                <Link
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                  to="/"
                >
                  Sobre
                </Link>
              </Scroll>
            </li>
            <li>
              <Scroll to="profile" spy smooth offset={-120}>
                <Link
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                  to="/"
                >
                  Terapeuta
                </Link>
              </Scroll>
            </li>
            <li>
              <Scroll to="contact" spy smooth offset={-120}>
                <Link
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                  to="/"
                >
                  Contato
                </Link>
              </Scroll>
            </li>
          </ul>
        </div>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/biomagnetismo" element={<Biomagnetismo />} />
        <Route path="/biofeedback" element={<Biofeedback />} />
        <Route path="/sobre" element={<Home />} />
        <Route path="/terapeuta" element={<Home />} />
        <Route path="/contato" element={<Home />} />
      </Routes>
    </Router>
  );
};
