import "./App.css";
import { About } from "./components/About";
import Contact from "./components/Contact";
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { Profile } from "./components/Profile";
import Footer from "./components/Footer";
import Biomagnetismo from "./biomagnetismo/components/Biomagnetismo";
import Biofeedback from "./biofeedback/components/Biofeedback";

function App() {
  return (
    <div className="App">
      <Header />
      {/* <Home />
      <About />
      <Profile />
      <Contact /> */}
      {/* <Biomagnetismo /> */}
      {/* <Biofeedback /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
